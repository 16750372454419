/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import './addToAny.css';
import './main.css';
import 'tailwindcss/tailwind.css';
import { useEffect } from 'react';
import App from 'next/app';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { ConfigProvider } from '../hooks/useConfig';
import { fetchConfig, getConfig, setConfig } from '../config/serverConfig';
import createI18n from '../config/createI18n';
import useApollo from '../hooks/useApollo';
import createTheme from '../config/createTheme';
import reducers from '../reducers';

function MyApp({ Component, pageProps, config }) {
  MyApp.config = config;
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-P9S3C7F' });
  });

  const apolloClient = useApollo(pageProps.initialApolloState);

  if (!config) return <Component {...pageProps} />;

  const { MuiTheme } = createTheme(config);

  return (
    <ConfigProvider config={config}>
      <Provider store={reducers}>
        <I18nextProvider i18n={createI18n(config.locale.language)}>
          <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={MuiTheme}>
              <Component {...pageProps} />
            </ThemeProvider>
          </ApolloProvider>
        </I18nextProvider>
      </Provider>
    </ConfigProvider>
  );
}

// NOTE: replace this with "getServerSideProps" when available
MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  let config = getConfig() || MyApp.config;

  if (!config) {
    try {
      console.log('sync: config required');
      config = await fetchConfig();

      setConfig(config);
      console.log('sync: ok');
    } catch (reason) {
      // TODO: find a way to respond 503
      console.error('sync error:', reason);
    }
  }

  return {
    config,
    ...appProps,
  };
};

export default MyApp;
