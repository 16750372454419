import * as nodeFetch from 'node-fetch';
import { fitsh } from 'fitsh';
import doOnce from '../utils/doOnce';

let config = null;

export function setConfig(newConfig) {
  config = newConfig;
}

export function getConfig() {
  return config;
}

function triggerRequest() {
  console.log('sync: requesting config', process.env.API_URL);

  const funnelApi = fitsh(nodeFetch, process.env.API_URL, {
    headers: {
      authorization: process.env.FUNNEL_AUTH,
    },
  });

  return funnelApi('api/config').get();
}

const schedule = doOnce(() => {
  if (process.env.DISABLE_UPDATER) return;

  console.log('sync: scheduling requester');
  setInterval(async () => {
    const newConfig = await triggerRequest();
    setConfig(newConfig);
  }, 5 * 60 * 1000);
});

export async function fetchConfig() {
  if (typeof window === 'undefined') {
    schedule();
  }
  return triggerRequest();
}
