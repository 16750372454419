import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '../translations';

let initialized = false;

export default function createI18n(lng) {
  if (initialized) return i18n;

  initialized = true;

  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      // lang config
      fallbackLng: ['en-US'],
      lng,
      resources: translations,

      // ns config
      ns: Object.keys(translations['en-US']),
      defaultNS: 'general',

      // general
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });

  return i18n;
}
