import details from './details.json';
import general from './general.json';
import search from './search.json';

const language = {
  details,
  general,
  search,
};

export default language;
