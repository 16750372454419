import { createMuiTheme } from '@material-ui/core/styles';
import Color from 'color';

/**
 * @param { string} rawColor
 * @returns { import('@material-ui/core/styles/createPalette').PaletteColor> }
 */
function createMuiColor(rawColor) {
  const color = new Color(rawColor);

  return {
    light: color.lighten(0.2).hex(),
    main: color.hex(),
    dark: color.darken(0.2).hex(),
    contrastText: color.isLight() ? '#222' : '#f5f5f5',
    // rgb components for rgba used only for tailwind to support background-opacity with css vars
    bg: `${color.red()}, ${color.green()}, ${color.blue()}`,
  };
}

/**
 * @param { import('../hooks/useConfig').Config } config
 */
export default function createTheme(config) {
  const { styles } = config;
  /**
   * @type { Record<string, import('@material-ui/core/styles/createPalette').PaletteColor> }
   */
  const colors = {
    askButton: createMuiColor(styles.askButtonColor),
    background: createMuiColor(styles.backgroundColor),
    breadcrumbs: createMuiColor(styles.breadcrumbsColor),
    drawer: createMuiColor(styles.drawerColor),
    footer: createMuiColor(styles.footerColor),
    header: createMuiColor(styles.headerColor),
    mercadolibre: createMuiColor(styles.mercadolibreColor),
    navLink: createMuiColor(styles.navLinkColor),
    oddRow: createMuiColor(styles.oddRowColor),
    previewPricing: createMuiColor(styles.previewPricingColor),
    pricing: createMuiColor(styles.pricingColor),
    primary: createMuiColor(styles.primaryColor),
    secondary: createMuiColor(styles.secondaryColor),
    separator: createMuiColor(styles.separatorColor),
    theme: createMuiColor(styles.themeColor),
    whatsapp: createMuiColor(styles.whatsappColor),
  };

  const MuiTheme = createMuiTheme({
    palette: {
      ...colors,
    },
  });

  const cssVars = Object.entries(colors).reduce(
    (result, [name, color]) => ({
      ...result,
      [`--${name}`]: color.main,
      [`--${name}-light`]: color.light,
      [`--${name}-dark`]: color.dark,
      [`--${name}-contrast`]: color.contrastText,
      [`--${name}-bg`]: color.bg,
    }),
    {},
  );

  cssVars['--tw-bg-opacity'] = '1';

  return { MuiTheme, cssVars };
}
