import createApolloClient from './createApolloClient';

/**
 * @type { import('@apollo/client').ApolloClient<import('@apollo/client').NormalizedCacheObject> }
 */
let apolloClient;

export default function initApollo(initialState = null) {
  const client = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that (use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    client.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return client;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = client;

  return client;
}
