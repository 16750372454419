import { configureStore } from '@reduxjs/toolkit';
import vehicleSlice from './vehicle';

const reducers = configureStore({
  reducer: {
    vehicle: vehicleSlice,
  },
});

export default reducers;
